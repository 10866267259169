import { template as template_2ae7a7d3565644b7bd59434b0bc065e8 } from "@ember/template-compiler";
const FKText = template_2ae7a7d3565644b7bd59434b0bc065e8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
