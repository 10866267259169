import { template as template_5cbaceb1aeda41f482d66c4ab48e8202 } from "@ember/template-compiler";
const WelcomeHeader = template_5cbaceb1aeda41f482d66c4ab48e8202(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
